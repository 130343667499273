@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.LogoDataBrand {
  background-image: url('./assets/img/Logo-databrand.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.LogoDataBrand10anos {
  background-image: url('./assets/img/pessoas-processos-tecnologia.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
.bg-efi {
  background-image: url('./assets/img/bg-efi.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-enfocus {
  background-image: url('./assets/img/EnfocusBG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-hynbrid {
  background-image: url('./assets/img/hybridEdatabrand.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-ultimate {
  background-image: url('./assets/img/headerbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}